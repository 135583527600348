(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/expanded-statistics-handler/assets/javascripts/expanded-statistics-handler.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/expanded-statistics-handler/assets/javascripts/expanded-statistics-handler.js');
"use strict";


const {
  getBetradarId
} = svs.components.oddset.kambiOffering;
const logger = new svs.core.log.Logger('oddset-kambi:components:expanded-statistics-handler');
const widgetEvents = svs.oddset_kambi.components.widgetEvents;
const {
  sportradarMatchUrl
} = svs.oddset_kambi.data;

const getExpandedStatistics = async eventId => {
  let betRadarId;
  try {
    betRadarId = await getBetradarId(eventId);
    if (!betRadarId) {
      logger.warn("Missing betRadarId for event ".concat(eventId));
      betRadarId = -1; 
    }
  } catch (error) {
    logger.error('Something went wrong while getting betRadarId. Error:', JSON.stringify(error));
    return;
  }
  if (!sportradarMatchUrl) {
    logger.error('sportradarMatchUrl is not defined');
    return;
  }
  widgetEvents.openExpandedStatistics(betRadarId);
};
setGlobal('svs.oddset_kambi.components.expandedStatisticsHandler.getExpandedStatistics', getExpandedStatistics);

 })(window);